// Libraries
import Helmet from "react-helmet";
import React, { Component } from "react";
import { gql } from "@apollo/client";
import client from "../lib/apollo-client.js";
import { Flex, Image } from "rebass";
import moment from "moment";
import Slider from "react-slick";

// Config
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();
const { SITE_NAME } = publicRuntimeConfig;

// Components
import {
	CallToAction,
	HoverCard,
	LayoutPadding,
	Video,
	Jumbotron,
	PageIntro,
	CardSlider,
	Layout,
	Text,
	Loading,
	RenderStreamField,
} from "../components";
import { colors } from "../styleguide/theme";
import { media_links } from "../mocks";
import { SplitContent } from "../components/fragments";

const SliderNextBtn = (props) => {
	const { style, onClick } = props;
	return (
		<a
			className="slick-arrow"
			href="#next" // Accessibility
			name="next" // Accessibility
			role="button" // Accessibility
			style={{
				width: "50px",
				height: "50px",
				position: "absolute",
				top: "50%",
				zIndex: 1,
				right: -75,
				marginRight: "100px",
				transform: "translateY(-50%)",
				borderBottom: "none",
			}}
			onClick={onClick}
		>
			<Image
				alt="Next"
				src="/static/icons/misc/chevron.svg"
				css={`
					width: 100%;
					transform: rotate(90deg);
				`}
			/>
		</a>
	);
};

const SliderPrevBtn = (props) => {
	const { style, onClick } = props;
	return (
		<div
			className="slick-arrow"
			href="#previous" // Accessibility
			role="button" // Accessibility
			name="previous"
			style={{
				width: "50px",
				height: "50px",
				position: "absolute",
				top: "50%",
				zIndex: 1,
				left: -75,
				marginLeft: "100px",
				transform: "translateY(-50%)",
			}}
			onClick={onClick}
		>
			<Image
				alt="Previous"
				src="/static/icons/misc/chevron.svg"
				css={`
					width: 70px;
					transform: rotate(-90deg);
				`}
			/>
		</div>
	);
};

class HomeImagesSlider extends Component {
	static defaultProps = {
		images: [],
	};

	render() {
		const settings = {
			dots: true,
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 5000,
			nextArrow: <SliderNextBtn />,
			prevArrow: <SliderPrevBtn />,
			appendDots: (dots) => (
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						backgroundColor: "transparent",
						position: "absolute",
						margin: 0,
						padding: 0,
						bottom: "5px",
					}}
				>
					<ul style={{ margin: 0, padding: 0 }}> {dots} </ul>
				</div>
			),
			customPaging: () => {
				return (
					<div
						className="slick__btn-active"
						style={{
							width: "10px",
							height: "10px",
							borderRadius: "50%",
							border: "1px white solid",
						}}
					></div>
				);
			},
		};
		return (
			<>
				{/* <Hide
          width={"100%"}
          hide={[0, 0, 0, 1]}
          css={`
            height: 100%;
          `}
        > */}
				<Slider {...settings}>
					{this.props.images.map((main) => (
						<Flex
							key={main.image.id}
							css={`
								z-index: -1;
							`}
						>
							<Jumbotron image={main.image.rendition.url} />
						</Flex>
					))}
				</Slider>
				{/* </Hide> */}
				{/* <Hide
          width={"100%"}
          hide={[1, 1, 1, 0]}
          css={`
            height: 100%;
          `}
        >
          <Jumbotron />
        </Hide> */}
			</>
		);
	}
}

const JumbotronContent = ({
	jumbotronTitle,
	jumbotronParagraph1,
	jumbotronParagraph2,
}) => {
	return (
		<PageIntro
			title={jumbotronTitle}
			paragraph={jumbotronParagraph1}
			paragraph2={jumbotronParagraph2}
		/>
	);
};

const Campaign = ({
	campaignsTitle,
	campaignsSubtitle,
	campaignsDescription,
	featuredCampaign1,
	featuredCampaign2,
}) => (
	<Flex>
		<LayoutPadding py={11}>
			<Flex flexDirection={"column"}>
				{/* BANNER */}
				<Flex
					width={"100%"}
					flexDirection={["column", "column", "row", "row"]}
				>
					{/* TITLE */}
					<Flex
						width={["100%", "100%", "100%", "40%"]}
						mr={["0", "0", 6, 6]}
						mb={[6, 6, 6, "0"]}
						color={colors.darkblack}
					>
						<Text as="h2">{campaignsTitle}</Text>
					</Flex>
					{/* SUBTITLE  & PARAGRAPH*/}
					<Flex
						width={["100%", "100%", "100%", "60%"]}
						flexDirection={"column"}
						pr={["0", "0", 6, 6]}
					>
						<Text as="h3" pb={5}>
							{campaignsSubtitle}
						</Text>
						<Text as="p">{campaignsDescription}</Text>
					</Flex>
				</Flex>
				{/* CAMPAIGN CARDS */}
				<Flex
					width={"100%"}
					flexDirection={["column", "column", "row"]}
					my={[0, 0, 10]}
				>
					{featuredCampaign1 && featuredCampaign1.jumbotronImage && (
						<HoverCard
							width={["100%", "100%", "50%"]}
							title={featuredCampaign1.jumbotronTitle}
							href={{
								pathname: `/campaigns/campaign/${featuredCampaign1.jumbotronTitle
									.split(" ")
									.join("-")}`,
								query: { id: featuredCampaign1.id },
							}}
							image={
								featuredCampaign1.jumbotronImage.rendition.url
							}
							paragraph={featuredCampaign1.cardDescription}
							mobileFontColor={colors.black}
						/>
					)}
					{featuredCampaign2 && featuredCampaign2.jumbotronImage && (
						<HoverCard
							width={["100%", "100%", "50%"]}
							title={featuredCampaign2.jumbotronTitle}
							href={{
								pathname: `/campaigns/campaign/${featuredCampaign2.jumbotronTitle
									.split(" ")
									.join("-")}`,
								query: { id: featuredCampaign2.id },
							}}
							image={
								featuredCampaign2.jumbotronImage.rendition.url
							}
							paragraph={featuredCampaign2.cardDescription}
							mobileFontColor={colors.black}
						/>
					)}
				</Flex>
				<Flex mb={[0, 0, 10]} mt={[10, 10, 0]}>
					<CallToAction
						title={"Learn More"}
						subColor={colors.black}
						href={"/campaigns"}
					/>
				</Flex>
			</Flex>
		</LayoutPadding>
	</Flex>
);

const VideoSection = ({
	mediaTitle,
	mediaParagraph,
	mediaCtaText,
	mediaCtaUrl,
	mediaBlock,
}) => (
	<>
		<Flex width={"100%"} flexDirection={["column"]} bg={colors.darkGrey}>
			<LayoutPadding py={11}>
				<Flex flexDirection={["column", "column", "row"]}>
					{/* TITLE AND PARAGRAPH CONTAINER */}
					<Flex
						flexDirection="column"
						width={["100%", "100%", "60%"]}
						mr={[8, 8, 10]}
					>
						{/* TITLE */}
						<Text as="h2" mb={[5]}>
							{mediaTitle}
						</Text>
						{/* PARAGRAPH */}
						<Text as="p">{mediaParagraph}</Text>
					</Flex>
					<Flex
						alignItems={"flex-start"}
						width={["100%", "100%", "40%"]}
						pl={[0, 0, 6, 12]}
						mt={[10, 10, "0"]}
					>
						<CallToAction
							title={mediaCtaText}
							subColor={colors.black}
							href={mediaCtaUrl}
						/>
					</Flex>
				</Flex>
			</LayoutPadding>
		</Flex>
		{/* EMBEDDED VIDEO */}
		<Flex width={["100%"]} justifyContent={"center"}>
			<Flex width={["100%", "100%", "60%"]}>
				<Video small src={mediaBlock[0].value} />
			</Flex>
		</Flex>
	</>
);

const ImpactSection = ({
	impactTitle,
	impactSubtitle,
	impactDescription,
	impactCtaTitle,
	impactCtaUrl,
	impactImage,
}) => (
	<SplitContent
		title={impactTitle}
		subTitle={impactSubtitle}
		paragraph={impactDescription}
		titleCTA={impactCtaTitle}
		href={impactCtaUrl}
		colorCTA={colors.black}
		image={impactImage.rendition.url}
	/>
);

const SeenOnTv = ({ icon_list }) => {
	return (
		<Flex width={"100%"} flexDirection={"column"}>
			<LayoutPadding py={11}>
				<Text as="h2" fontSize={[6, 6, 7]} fontWeight={100}>
					As Seen On
				</Text>
				{!!icon_list.length && (
					<Flex
						width={"100%"}
						mt={[7, 7, 9]}
						justifyContent={"space-between"}
						alignItems={"center"}
						flexDirection={"row-reverse"}
					>
						{icon_list.map((img) => {
							return (
								<a
									target="_blank"
									href={`${img.externalLink}`}
									key={img.id}
								>
									<Image
										src={img.image.rendition.url}
										css={`
											height: 6vw;
											filter: grayscale(1);
											opacity: 0.35;

											&:hover {
												filter: grayscale(0);
												opacity: 1;
											}
										`}
									/>
								</a>
							);
						})}
					</Flex>
				)}
			</LayoutPadding>
		</Flex>
	);
};

const GET_HOMEPAGE = gql`
	query getHomePage($first: Int!) {
		homepage {
			edges {
				node {
					title # page tab title
					tabDescription # tab meta
					searchDescription # meta
					jumbotronSlider {
						... on JumbotronSliderBlockImage {
							image {
								id
								rendition(format: "jpeg", fill: "1845x1040") {
									url
								}
							}
						}
					}
					jumbotronTitle #Banner Section
					jumbotronParagraph1
					jumbotronParagraph2
					body {
						... on SectionBannerBlock {
							blockType
							title
							buttonCta
							buttonHref
							backgroundColor
							image {
								rendition(min: "1840x1083") {
									url
									height
								}
							}
						}
						... on DefaultStreamBlock {
							blockType
							value
						}
						... on SectionBlockThree {
							blockType
							title
							paragraph
							backgroundColor
							image {
								rendition(min: "1840x1083") {
									url
								}
							}
							cta
							ctaLink
						}
						... on SectionBlockFour {
							blockType
							title
							paragraph
							backgroundColor
							image {
								rendition(min: "1840x1083") {
									url
								}
							}
						}
						... on SectionBlockFive {
							blockType
							title
							subTitle
							paragraph
							paragraph2
						}
						... on SectionBlockSix {
							blockType
							paragraph
						}
						... on SectionBlockSeven {
							blockType
							title
							paragraph
							image {
								rendition(min: "1840x1083") {
									url
								}
							}
							cta
							ctaLink
						}
						... on SectionBlockTen {
							blockType
							paragraph
							paragraph2
							backgroundColor
						}
						... on SectionBlockEleven {
							blockType
							paragraph
							paragraph2
							paragraph3
							backgroundColor
						}
					}
					eventsTitle #Upcoming Events
					eventsSubtitle
					eventsDescription
					seeAllEventsCtaText
					campaignsTitle # Campaign Section
					campaignsSubtitle
					campaignsDescription
					featuredCampaign1 {
						cardDescription
						jumbotronImage {
							rendition(format: "jpeg", min: "1840x1035") {
								url
							}
						}
						jumbotronTitle
						id
					}
					featuredCampaign2 {
						cardDescription
						jumbotronImage {
							rendition(format: "jpeg", min: "1840x1035") {
								url
							}
						}
						jumbotronTitle
						id
					}
					mediaTitle # Media Section
					mediaParagraph
					mediaCtaText
					mediaCtaUrl
					mediaBlock {
						... on DefaultStreamBlock {
							blockType
							value
						}
					}
					impactTitle #Impact Section
					impactSubtitle
					impactDescription
					impactCtaTitle
					impactCtaUrl
					impactImage {
						rendition(format: "jpeg", min: "1840x1035") {
							url
						}
					}
					# asSeenOnGalleryImages {
					# 	id
					# 	image {
					# 		rendition(format: "jpeg", min: "200x133") {
					# 			url
					# 		}
					# 	}
					# 	externalLink
					# }
				}
			}
		}
		upcomingEvents: events(first: $first, orderBy: "-start_date") {
			edges {
				node {
					id
					detailsTitle
					startDate
					locationName
					jumbotronImage {
						rendition(format: "jpeg", min: "1840x1083") {
							url
						}
					}
				}
			}
		}
	}
`;

const Index = ({ data, error }) => {
	const {
		title,
		tabDescription,
		searchDescription,
		jumbotronSlider,
		jumbotronTitle,
		jumbotronParagraph1,
		jumbotronParagraph2,
		eventsTitle,
		body,
		eventsSubtitle,
		eventsDescription,
		seeAllEventsCtaText,
		featuredCampaign1,
		featuredCampaign2,
		campaignsTitle,
		campaignsSubtitle,
		campaignsDescription,
		mediaTitle,
		mediaParagraph,
		mediaCtaText,
		mediaCtaUrl,
		mediaBlock,
		impactTitle,
		impactSubtitle,
		impactDescription,
		impactCtaTitle,
		impactCtaUrl,
		impactImage,
		asSeenOnGalleryImages,
	} = data.homepage.edges[0].node;

	const upcomingEventsArray = data.upcomingEvents.edges.map((event) => {
		return {
			id: event.node.id,
			title: event.node.detailsTitle,
			date: moment(event.node.startDate).format("LL"),
			location: event.node.locationName,
			image: event.node.jumbotronImage.rendition.url,
			href: {
				pathname: "/events/event",
				query: {
					title: event.node.title,
					id: event.node.id,
					view: "details",
				},
			},
		};
	});

	return (
		<React.Fragment>
			<Layout {...{ title, tabDescription, searchDescription }}>
				<HomeImagesSlider images={jumbotronSlider} />
				<JumbotronContent
					{...{
						jumbotronTitle,
						jumbotronParagraph1,
						jumbotronParagraph2,
					}}
				/>
				{body && <RenderStreamField body={body} />}
				<CardSlider
					title={eventsTitle}
					subTitle={eventsSubtitle}
					paragraph={eventsDescription}
					buttonTitle={seeAllEventsCtaText}
					buttonHighlight
					href={"/events/see-all"}
					data={upcomingEventsArray}
				/>
				<Campaign
					{...{
						campaignsTitle,
						campaignsSubtitle,
						campaignsDescription,
						featuredCampaign1,
						featuredCampaign2,
					}}
				/>
				<VideoSection
					{...{
						mediaTitle,
						mediaParagraph,
						mediaCtaText,
						mediaCtaUrl,
						mediaBlock,
					}}
				/>
				<ImpactSection
					{...{
						impactTitle,
						impactSubtitle,
						impactDescription,
						impactCtaTitle,
						impactCtaUrl,
						impactImage,
					}}
				/>
				{/* Client Asked to Remove <SeenOnTv icon_list={asSeenOnGalleryImages} /> */}
			</Layout>
		</React.Fragment>
	);
};

const gqlVariables = {
	first: 10,
};

export async function getServerSideProps() {
	const { data, error } = await client.query({
		query: GET_HOMEPAGE,
		variables: gqlVariables,
	});

	return {
		props: {
			data: error ? {} : data,
			error: error ? true : false,
		},
	};
}

export default Index;
